<template>
  <div>
    <div id="pc">
      <div class="pc-top">
        <div style="line-height: 1.5rem">
          <img src="../../assets/公司简介/服务案例.png" alt="" />
        </div>
      </div>
      <div class="breadmenu" style="background-color: rgb(17, 20, 33);">
        <el-breadcrumb separator-class="el-icon-arrow-right" style="background-color: rgb(17, 20, 33);">
          <el-breadcrumb-item style="font-size: 0.12rem;" :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 0.1rem;" :to="{ path: '/case' }">服务案例</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="bussBox">
        <div style="width: 60%;margin: 50px auto;">
          <div class="service">
            <div v-for="(item, index) in pcprogrem" :key="index" class="serviceson" @click="todetail(item)">
              <div style="width: 479px; height: 250px;"><a style="color: rgb(255, 255, 255);"><img :src="item.showImage"
                    alt="" style="width: 100%; height: 100%;">
                  <p
                    style="width: 80%; font-size: 23px; text-align: center; padding: 10px 0px; background: rgba(0, 0, 0, 0.5); position: absolute; top: 0px; left: 0px; transform: translate(12%, 320%); color: rgb(255, 255, 255);">
                    {{ item.title }} </p>
                </a></div>
            </div>
          </div>
        </div>
        <div style="margin: 20px auto">
          <div style="
                            margin: auto;
                            width: 55%;
                            display: flex;
                            justify-content: space-around;
                          ">
            <div>
              <!-- <el-button
                :disabled="currentPage === 1"
                class="first-pager"
                @click="toFirstPage"
                >首页</el-button
              > -->
            </div>
            <el-pagination ref="pagination" background layout="prev, pager, next" :current-page="currentPage"
              :page-count="totalpage" @current-change="changecurrent" next-text="下一页" prev-text="上一页" :small="true">
            </el-pagination>
            <div style="height: 51px">
              <!-- <el-button class="last-pager" @click="toLastPage">末页</el-button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="mobiel" style="background-color:#111421">
      <div class="mobielhead">
        <div style="line-height: 2rem">
          <img src="../../assets/pc服务案例/服务案例.png" alt="" style="width:3rem" />
        </div>
      </div>
      <div class="mobiel-contain">
        <div class="mobiel-case">
          <div v-for="(item, index) in moprogrem" :key="index" class="mobiel-cases" @click="todetail(item)">
            <div>
              <img :src="item.showImage" alt="" style=" width: auto;
                                height: auto;
                                max-width: 100%;
                                max-height: 100%;
                              " />
            </div>
            <div style="color: #d3bc93; font-size: 25px; margin-top: 10px">
              {{ item.highlightTitle }}
            </div>
          </div>
          <div style="text-align:center;color:#d3bc93" @click="getmore" v-if="mototal < mototals">更多</div>
        </div>
      </div>
      <div style="width: 100%; height: 100px"></div>
    </div>
  </div>
</template>
<script>
import { getUserInfo, setUserInfo, getCurrentCompany } from "@/utils/storage";
import { postAction } from "@/api/manage";
// import img0 from "../../assets/pc服务案例/图层 14.png";
// import img0 from "../../assets/pc服务案例/图层 15.png";
// import img0 from "../../assets/pc服务案例/图层 16.png";
// import img0 from "../../assets/pc服务案例/图层 17.png";
// import img0 from "../../assets/pc服务案例/图层 18.png";
// import img0 from "../../assets/pc服务案例/图层 19.png";
export default {
  data() {
    return {
      mototal: 0,
      mototals: null,
      mocurrentPage: 1,
      mopagesize: 3,
      currentPage: 1,
      pageSize: 6,
      moprogrem: [],
      pcprogrem: [],
      totalpage: null,
      imgs: [
        { url: require("../../assets/pc服务案例/图层 14.png") },
        { url: require("../../assets/pc服务案例/图层 15.png") },
        { url: require("../../assets/pc服务案例/图层 16.png") },
        { url: require("../../assets/pc服务案例/图层 17.png") },
        // { url: require("../../assets/pc服务案例/图层 18.png") },
        // { url: require("../../assets/pc服务案例/图层 14.png") },
      ],
      imgs1: [
        {
          url: require("../../assets/pc服务案例/图层 14.png"),
          value: "应急项目落地",
        },
        {
          url: require("../../assets/pc服务案例/图层 15.png"),
          value: "应急是打开链接目落地",
        },
        {
          url: require("../../assets/pc服务案例/图层 16.png"),
          value: "应急项目落地计划",
        },
        {
          url: require("../../assets/pc服务案例/图层 14.png"),
          value: "应急项目落地",
        },
        {
          url: require("../../assets/pc服务案例/图层 17.png"),
          value: "应急项目落地方法",
        },
        {
          url: require("../../assets/pc服务案例/图层 18.png"),
          value: "应是急项目落地",
        },
        {
          url: require("../../assets/pc服务案例/图层 14.png"),
          value: "应急撒是否项目落地",
        },
      ],
      userInfo: {
        setting: [],
      },
      bindData: "",
      bannerList: [
        {
          url: require("../../assets/website/shou1.jpg"),
          // url:"../../assets/website/shou1.jpg"
        },
        {
          url: require("../../assets/website/shou2.jpg"),
          // url:"../../assets/website/shou2.jpg"
        },
      ],
    };
  },
  created() { },
  activated() { },
  methods: {
    todetail(val) {
      window.open(`/casedetail?id=${val.id}&slug=${val.siteId}`)
      // this.$router.push({
      //   path: "/casedetail",
      //   query: {
      //     id: val.id,
      //     slug: val.siteId
      //   }
      // });
    },
    getlist() {
      postAction('/api/article/paginate', {
        categoryId: 12,
        pageNumber: this.currentPage,
        pageSize: this.pageSize
      }, { 'Content-Type': 'application/x-www-form-urlencoded' }).then((res) => {
        // console.log(res,'后台返回')
        this.pcprogrem = res.page.list
        this.totalpage = res.page.totalPage
      })
    },
    changecurrent(val) {
      this.currentPage = val
      this.getlist()
    },
    getmore() {
      this.mocurrentPage++
      this.getmobiellist()
    },
    getmobiellist() {
      postAction('/api/article/paginate', {
        categoryId: 12,
        pageNumber: this.mocurrentPage,
        pageSize: this.mopagesize
      }, { 'Content-Type': 'application/x-www-form-urlencoded' }).then((res) => {
        // console.log(res.page.list.length,'changdu')
        this.mototal += res.page.list.length
        this.mototals = res.page.totalRow
        // console.log(res,'返回')
        res.page.list.forEach(item => {
          this.moprogrem.push(item)
        });

        //this.totalpage =res.page.totalPage
      })
    },
  },
  mounted() {
    this.getlist()
    this.getmobiellist()
    // 判断pc和移动
    // let str = window.navigator.userAgent;
    // if (str.toLowerCase().indexOf("mobile") == -1) {
    //   console.log(document.getElementById("pc"), "pc777777777777");
    //   document.getElementById("pc1").style.display = "block";
    //   document.getElementById("mobiel1").style.display = "none";
    // } else {
    //   document.getElementById("mobiel1").style.display = "block";
    //   document.getElementById("pc1").style.display = "none";
    // }
  },
  destroyed() { },
};
</script>
<style lang="less" scoped>
#pc {
  display: none;
}

#mobiel {
  display: none;
}

@media screen and (max-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc {
    display: none;
  }

  #mobiel {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc {
    display: block;
  }

  #mobiel {
    display: none;
  }
}

.mobiel-cases {
  border: 1px soild #d3bc93;
  text-align: center;
  margin: 0.4rem 0;
}

/deep/ .el-pagination--small span:not([class*="suffix"]) {
  font-size: 5px;
  font-weight: 300;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  // background-color: #e19017;
  color: white;
  background-color: rgb(211, 188, 147);
}

/deep/ .el-pagination.is-background.el-pagination--small .el-pager li {
  margin: 0 0.04rem;
  min-width: 0.29333rem;
  font-size: 0.09333rem;
  background-color: rgb(24, 29, 47);
  color: rgb(211, 188, 147);
}

/deep/.el-pagination.is-background.el-pagination--small .btn-prev,
/deep/.el-pagination.is-background.el-pagination--small .btn-next {
  color: rgb(211, 188, 147);
  font-size: 0.09333rem;
  background-color: rgb(24, 29, 47);
}

/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner {
  color: #d9bb95;
}

/deep/.el-breadcrumb__inner.is-link {
  color: #d9bb95;
}

.bussBox {
  background-image: url('~@/assets/images/back.png');
  background-position: 50% center;
  border-top: 0.01333rem solid rgb(211, 188, 147);
  padding-bottom: 0.66667rem;
}

.mobiel-case {
  width: 80%;
  margin: auto;
}

.mobielhead {
  background-image: url(../../assets/招贤纳士/图层\ 10.png);
  height: 2rem;
  text-align: center;
}

.el-pagination {
  text-align: center;
  font-weight: 300;
  display: inline-block;
}

.serviceson {
  width: 32%;
  display: flex;
  flex-wrap: wrap;
  margin-right: 0.06667rem;
  margin-top: 0.06667rem;
  overflow: hidden;
  position: relative;
}

.service {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0px auto;
}

.pc-top {
  background-image: url(../../assets/招贤纳士/图层\ 14.png);
  width: 100%;
  height: 1.5rem;
  background-color: ;
  text-align: center;
}

.el-breadcrumb {
  font-size: 0.05rem;
  line-height: 0.35rem;
  background-color: #fff;
  height: 0.35rem;
  padding-left: 2.8rem;
}
</style>

<style>
::-webkit-scrollbar {
  display: none;
}
</style>